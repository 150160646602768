import Prism from "prismjs";
import Vue from '../../../node_modules/vue/dist/vue.min';

import bootstrap from '../../../node_modules/bootstrap/dist/js/bootstrap.bundle'

window.Vue = Vue;
window.bootstrap = bootstrap;

import '../../../node_modules/prismjs/components/prism-markup-templating'
import '../../../node_modules/prismjs/components/prism-php'
import '../../../node_modules/prismjs/components/prism-java'
import '../../../node_modules/prismjs/components/prism-csharp'
import '../../../node_modules/prismjs/components/prism-javascript'
import '../../../node_modules/prismjs/components/prism-ruby'
import '../../../node_modules/prismjs/components/prism-go'
import '../../../node_modules/prismjs/components/prism-python'
import '../../../node_modules/prismjs/components/prism-swift'
import '../../../node_modules/prismjs/components/prism-kotlin'


